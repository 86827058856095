import { Inject, Injectable } from '@angular/core';
import {
	BehaviorSubject,
	catchError,
	map,
	Observable,
	of,
	switchMap,
	tap,
} from 'rxjs';

import { ENVIRONMENTS, Environments } from '@emrm/core/environments';
import { ApiService } from '@emrm/core/services/api';
import {
	PermissionApp,
	PermissionCrudCode,
	PermissionModule,
	PermissionOption,
} from '@emrm/core/permissions/types';
import { MANAGE_APP_CODE } from '@emrm/manage/types';

import { initialState, PermissionsStoreState } from './state';

@Injectable({
	providedIn: 'root',
})
export class PermissionsStoreService {
	private state = initialState;
	private stateSubject = new BehaviorSubject<PermissionsStoreState>(this.state);

	constructor(
		@Inject(ENVIRONMENTS) private readonly environments: Environments,
		private apiService: ApiService,
	) {}

	select<T>(property: keyof PermissionsStoreState) {
		return this.stateSubject.pipe(map((state) => state[property] as T));
	}

	load() {
		this.state = {
			...this.state,
			loading: true,
			error: null,
		};
		this.stateSubject.next(this.state);

		return this.apiService
			.get$<PermissionApp[]>(`${this.environments.apiUrl}/permissions`)
			.pipe(
				tap((response) => {
					this.state = {
						...this.state,
						loading: false,
						permissions: response,
					};

					this.stateSubject.next(this.state);
				}),
				catchError((error) => {
					this.state = {
						...this.state,
						loading: false,
						permissions: null,
						error: error.message,
					};
					this.stateSubject.next(this.state);

					return of(error);
				}),
			);
	}

	/**
	 * Получает права пользователя для указанного кода приложения.
	 */
	getPermission(appCode: string) {
		if (!this.state.permissions) {
			return null;
		}

		return this.state.permissions.find((app) => app.code === appCode) || null;
	}

	getPermissionAppWithAccess$(
		appCode: string,
	): Observable<{ granted: boolean; appPermission?: PermissionApp }> {
		if (appCode === MANAGE_APP_CODE) {
			return of({ granted: true });
		}

		if (this.state.permissions) {
			return of(
				this.checkAccessPermissionToApp(this.state.permissions, appCode),
			);
		}

		return this.load().pipe(
			switchMap((response) => {
				if (!response) {
					return of({ granted: false });
				}

				return of(this.checkAccessPermissionToApp(response, appCode));
			}),
		);
	}

	getPermissionModuleWithAccess$(
		appCode: string,
		moduleCode: string,
	): Observable<{
		granted: boolean;
		appPermission?: PermissionApp;
		modulePermission?: PermissionModule;
	}> {
		if (appCode === MANAGE_APP_CODE) {
			return of({ granted: true });
		}

		if (this.state.permissions) {
			return of(
				this.checkAccessPermissionToModule(
					this.state.permissions,
					appCode,
					moduleCode,
				),
			);
		}

		return this.load().pipe(
			switchMap((response) => {
				if (!response) {
					return of({ granted: false });
				}

				return of(
					this.checkAccessPermissionToModule(response, appCode, moduleCode),
				);
			}),
		);
	}

	getPermissionOptionWithAccess$(
		appCode: string,
		moduleCode: string,
		optionCode: string,
	): Observable<{
		granted: boolean;
		appPermission?: PermissionApp;
		modulePermission?: PermissionModule;
		optionPermission?: PermissionOption;
	}> {
		if (appCode === MANAGE_APP_CODE) {
			return of({ granted: true });
		}

		if (this.state.permissions) {
			return of(
				this.checkAccessPermissionToOption(
					this.state.permissions,
					appCode,
					moduleCode,
					optionCode,
				),
			);
		}

		return this.load().pipe(
			switchMap((response) => {
				if (!response) {
					return of({ granted: false });
				}

				return of(
					this.checkAccessPermissionToOption(
						response,
						appCode,
						moduleCode,
						optionCode,
					),
				);
			}),
		);
	}

	/**
	 * Проверяет доступ пользователя к приложению.
	 */
	hasAccessToApp(appCode: string) {
		if (appCode === MANAGE_APP_CODE) {
			return true;
		}

		if (!this.state.permissions) {
			return false;
		}

		return this.checkAccessPermissionToApp(this.state.permissions, appCode)
			.granted;
	}

	/**
	 * Проверяет доступ пользователя к модулю приложения.
	 */
	hasAccessToModule(appCode: string, moduleCode: string) {
		if (!this.state.permissions) {
			return false;
		}

		return this.checkAccessPermissionToModule(
			this.state.permissions,
			appCode,
			moduleCode,
		).granted;
	}

	/**
	 * Проверяет доступ пользователя к параметру модуля приложения.
	 */
	hasAccessToModuleOption(
		appCode: string,
		moduleCode: string,
		optionCode: string,
	) {
		if (!this.state.permissions) {
			return false;
		}

		return this.checkAccessPermissionToOption(
			this.state.permissions,
			appCode,
			moduleCode,
			optionCode,
		).granted;
	}

	/**
	 * Проверяет доступ пользователя к CRUD параметру "Изменение" модуля приложения.
	 */
	hasCrudUpdatePermissionToModule(appCode: string, moduleCode: string) {
		if (!this.state.permissions) {
			return false;
		}

		return this.checkCrudUpdatePermissionToModule(
			this.state.permissions,
			appCode,
			moduleCode,
		).granted;
	}

	/**
	 * Проверяет доступ пользователя к CRUD параметру "Создание" модуля приложения.
	 */
	hasCrudCreatePermissionToModule(appCode: string, moduleCode: string) {
		if (!this.state.permissions) {
			return false;
		}

		return this.checkCrudCreatePermissionToModule(
			this.state.permissions,
			appCode,
			moduleCode,
		).granted;
	}

	/**
	 * Проверяет доступ пользователя к приложению.
	 *
	 * @param permissions Права доступа пользователя.
	 * @param appCode Код приложения.
	 * @returns Возвращает { granted: false }, если доступ запрещен,
	 * иначе { granted: true, appPermission },
	 * где:
	 * appPermission - права доступа приложения (необходимо для дальнейших проверок)
	 */
	private checkAccessPermissionToApp(
		permissions: PermissionApp[] | null,
		appCode: string,
	) {
		if (!permissions) {
			return { granted: false };
		}

		const appPermission = permissions?.find((x) => x.code === appCode);

		if (!appPermission) {
			return { granted: false };
		}

		let hasPermissionToAnyModule = false;
		for (const module of appPermission.modules) {
			if (module.status) {
				hasPermissionToAnyModule = true;
				break;
			}
		}

		return {
			appPermission,
			granted: appPermission.status && hasPermissionToAnyModule,
		};
	}

	/**
	 * Проверяет доступ пользователя к модулю приложения.
	 *
	 * @param permission Права доступа пользователя.
	 * @param appCode Код приложения.
	 * @param moduleCode Код модуля.
	 * @returns Возвращает { granted: false }, если доступ запрещен,
	 * иначе { granted: true, appPermission, modulePermission },
	 * где:
	 * appPermission - права доступа приложения,
	 * modulePermission - права доступа модуля (необходимо для дальнейших проверок)
	 */
	private checkAccessPermissionToModule(
		permission: PermissionApp[] | null,
		appCode: string,
		moduleCode: string,
	) {
		const appCheck = this.checkAccessPermissionToApp(permission, appCode);

		if (!appCheck.granted) {
			return { granted: false };
		}

		const modulePermission = appCheck.appPermission?.modules.find(
			(x) => x.code === moduleCode,
		);

		if (!modulePermission) {
			return { granted: false };
		}

		return {
			appPermission: appCheck.appPermission,
			modulePermission: modulePermission,
			granted: modulePermission.status || false,
		};
	}

	/**
	 * Проверяет доступ пользователя к параметру модуля приложения.
	 *
	 * @param permission Права доступа пользователя.
	 * @param appCode Код приложения.
	 * @param moduleCode Код модуля.
	 * @param optionCode Код параметра.
	 * @returns Возвращает { granted: false }, если доступ запрещен,
	 * иначе { granted: true, appPermission, modulePermission },
	 * где:
	 * appPermission - права доступа приложения,
	 * modulePermission - права доступа модуля,
	 * optionPermission - права доступа параметра (необходимо для дальнейших проверок)
	 */
	private checkAccessPermissionToOption(
		permission: PermissionApp[] | null,
		appCode: string,
		moduleCode: string,
		optionCode: string,
	) {
		const moduleCheck = this.checkAccessPermissionToModule(
			permission,
			appCode,
			moduleCode,
		);

		if (!moduleCheck.granted) {
			return { granted: false };
		}

		const optionPermission = moduleCheck.modulePermission?.link_options.find(
			(x) => x.code === optionCode,
		);

		if (!optionPermission) {
			return { granted: false };
		}

		return {
			appPermission: moduleCheck.appPermission,
			modulePermission: moduleCheck.modulePermission,
			optionPermission: optionPermission,
			granted: optionPermission.status || false,
		};
	}

	private checkCrudUpdatePermissionToModule(
		permissions: PermissionApp[] | null,
		appCode: string,
		moduleCode: string,
	) {
		if (!permissions) {
			return { granted: false };
		}

		const moduleCheck = this.checkAccessPermissionToModule(
			permissions,
			appCode,
			moduleCode,
		);

		if (!moduleCheck.granted) {
			return { granted: false };
		}

		const updatePermission = moduleCheck.modulePermission?.link_crud.find(
			(x) => x.code === PermissionCrudCode.Update,
		);

		if (!updatePermission) {
			return { granted: false };
		}

		return { granted: updatePermission.status };
	}

	private checkCrudCreatePermissionToModule(
		permissions: PermissionApp[] | null,
		appCode: string,
		moduleCode: string,
	) {
		if (!permissions) {
			return { granted: false };
		}

		const moduleCheck = this.checkAccessPermissionToModule(
			permissions,
			appCode,
			moduleCode,
		);

		if (!moduleCheck.granted) {
			return { granted: false };
		}

		const updatePermission = moduleCheck.modulePermission?.link_crud.find(
			(x) => x.code === PermissionCrudCode.Create,
		);

		if (!updatePermission) {
			return { granted: false };
		}

		return { granted: updatePermission.status };
	}
}
